import {useNuxtApp} from '#app';
import {useApi} from '~/composables/api';
import {useNews} from '~/stores/news';

export default defineNuxtRouteMiddleware(async to => {
	// const app = useNuxtApp();
	// const api = useApi();
	const news = useNews();

	console.log('%c- middleware DETECT URL NEWS', 'color:#0a0', process.server ? 'server' : 'frontend');

	// console.log('to', to)
	switch (to.name) {
		case 'news':
			// TODO: validation filter_id

			if (to.query?.filter?.length) {
				const regex = new RegExp(/^[a-fA-F0-9]{64}$/);

				if (regex.test(to.query.filter)) {
					const api = useApi();
					const filters = await api.news.fetchFilterId({filterId: to.query.filter});

					if (filters) {
						// news.filterId = to.query.filter;
						news.setFilters(filters);
					}
				} else {
					console.log('Not validate filter ID')
				}
			} else {
				news.clearFilters();
			}
			break;

		case 'news-influencer':
			// const response = await api.news.fetchInfluencerBySlug(to.params.influencer);
			//
			// if (response) {
			// 	const influencer = response.influencer;
			//
			// 	if (influencer && news.checkEmptyFilters(news.filters)) {
			// 		news.setInfluencer(influencer.id);
			// 	}
			//
			// 	// useSeoMeta({
			// 	// 	title: `${influencer.name} - DOXXY News`,
			// 	// 	ogTitle: `${influencer.name} - DOXXY News`,
			// 	// 	description: influencer.description,
			// 	// 	ogDescription: influencer.description,
			// 	// });
			// }

			break;

		case 'news-articles':
			break;

		// case 'influencer':
		// 	break;
		// case 'influencer':
		// 	break;
	}
})
